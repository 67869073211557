
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const homeControllerCruds = {

    getMenu : () => {
        return axios.get(`/homeController/menus`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },

    
    getOffer: async () => {
        const cacheName = "menuImagesCache";
    
        const storeImageInCache = async (url) => {
            try {
                if (url) {
                    const cache = await caches.open(cacheName);
                    const response = await fetch(url);
    
                    if (response.ok) {
                        await cache.put(url, response); 
                    } else {
                        console.warn(`Failed to fetch the image: ${url}`);
                    }
                }
            } catch (error) {
                debugger
                console.error(`Error storing image in cache for URL: ${url}`, error);
            }
        };
    
        const getImageFromCache = async (url) => {
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(url);
            if (cachedResponse) {
                console.log(`Image served from Cache Storage: ${url}`);
                const imageBlob = await cachedResponse.blob(); 
                return URL.createObjectURL(imageBlob); 
            } else {
                debugger
                return null;
            }
        };
    
        try {
            const response = await axios.get(`/recipeData/byGroupCode/data`);
            if (response.data.data) {
                const data = JSON.parse(response.data.data); 
    
          
                    const offersArray = data['data']; 
                        console.log(offersArray)
                    for (const offer of offersArray) {
                        if (offer.image) {
                            const cachedImageUrl = await getImageFromCache(offer.image);
                            if (cachedImageUrl) {
                                offer.imageUrl = cachedImageUrl; 
                            } else {
                                await storeImageInCache(offer.image); 
                            }
                        }
                    }
                
    
                return data; 
            } else {
                debugger
                return Promise.reject("No data found");
            }
        } catch (err) {
            debugger
            return Promise.reject({ message: err.message, status: err?.response?.status });
        }
    },
    
        
    getOfferById : ({ id }) => {
        return axios.get(`/homeController/offers/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOfferPaginated : () => {
        return axios.get(`/homeController/offers/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOfferInfo : () => {
        return axios.get(`/homeController/offersinfo/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getPopularPizza : () => {
        return axios.get(`/homeController/popular`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default homeControllerCruds;
    
