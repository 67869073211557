import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./cart.scss";
import cartImg from "../../assets/svg-icons/Cart.svg";
import ImageWithAltImage from "../../componets/etc/ImageWithAltImage";
import IncDecCounter from "../../componets/FormComponent/IncDecCounter";
import { Collapse } from "react-bootstrap";

import ROUTES from "../../constants/ROUTES";
import { useHistory } from "react-router";
import {
  calcSurchargeAmt,
  calcToppings,
  cartActions,
  getTotalOfSurcharges,
  CART_TRACKING_PAGES,
  cartSlice,
} from "../../_redux/files/cartRedux";
import {
  letsRound,
  letsFloor,
  isMobileDevice,
  makeMinOrderValueToTwoDecimal,
} from "../../helpers/Utils";
import debounce from "lodash.debounce";
import { useCallback } from "react";
//import { useDebounce } from "../../componets/etc/UseDebounce";

import { outletShiftActions } from "../../_redux/files/outletShiftRedux";
import { geographyMSTActions } from "../../_redux/files/geographyMSTRedux";
import { deliveryChargesDTLActions } from "../../_redux/files/deliveryChargeRedux";
import { hnhSlice } from "../../_redux/files/hnhRedux";
import PaymentIncDec from "../../componets/FormComponent/PaymentIncDec";

function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay] // will recreate if delay changes
  );
  return debouncedFn;
}
const ToppingsCollapse = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="toppings-collapse">
      <div
        className={`collapse-header ${isOpen ? "expanded" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className={`mr-2 fas fa-chevron-${isOpen ? "down" : "right"}`}></i>
        {title}
      </div>
      <Collapse in={isOpen}>
        <div className="collapse-body">{children}</div>
      </Collapse>
    </div>
  );
};

const SauceShow = ({ title }) => {
  return (
    <div className="toppings-collapse">
      <div className={`collapse-header`}>{title}</div>
    </div>
  );
};

const Cart = ({
  nextBtn,
  backBtn,
  onEditBtnClick,
  activeSubpage,
  activeSwap,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [itemsData, setItemsdata] = useState();
  const [numItem, setNumItem] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const {
    error,
    loading,
    items,
    totalItems,
    subTotal,
    savedAmount,
    voucher,
    voucherCode,
    surcharges,
    showPopUpCart,
    deliveryCharge,
    combSeqForSwap,
  } = useSelector((s) => s.cart);
  const setVoucherCode = (code) => dispatch(cartActions.setVoucherCode(code));

  const { swap, offerLocalId } = useSelector((s) => s.cart);

  const customizeLocalId = useSelector((s) => s.customize?.item?.localId);
  const builtLocalId = useSelector((s) => s.cart?.localIdForBuildYourOwn);
  const localIdForHnhEdit = useSelector((state) => state.hnh.localIdForEdit);
  const orderType = useSelector((state) => state.orderMST.orderType);

  const [cartTrackError, setCartTrackError] = useState(null);
  const [minOrderValue, setMinOrderValue] = useState(null);
  const {
    checkMinimum,
    loadingGetMinimumOrder,
    orderTypeForMinOrder,
    customerAddress,
    minOrderData,
  } = useSelector((state) => {
    return {
      checkMinimum: state.deliveryChargeDTL.checkMinimum.data,
      loadingGetMinimumOrder: state.deliveryChargeDTL.checkMinimum.loading,
      orderTypeForMinOrder: state.orderMST.orderType,
      customerAddress: state.orderMST.customerAddress.data,
      minOrderData: state.deliveryChargeDTL.checkMinimum.data,
    };
  });



  useEffect(() => {
    // this code is change because of min order is not working
    setMinOrderValue(makeMinOrderValueToTwoDecimal(checkMinimum));
    // if (checkMinimum) {
    //   let { minimumOrderValue } = checkMinimum;
    //   // let { minimumOrderValue } = checkMinimum[0];
    //   // console.log(minimumOrderValue);
    //   // console.log(checkMinimum);
    //   // console.log(makeMinOrderValueToTwoDecimal(checkMinimum));
     
    // } else {
    //   if (customerAddress?.suburbId) {
    //     if (!minOrderData && !loadingGetMinimumOrder) {
    //       if (customerAddress?.suburbId) {
    //         dispatch(
    //           deliveryChargesDTLActions.getMinimumOrderValue(
    //             customerAddress?.suburbId
    //           )
    //         ).then((res) => {
    //           console.log(res);
    //           // let { minimumOrderValue } = res[0];
    //           setMinOrderValue(
    //             makeMinOrderValueToTwoDecimal(res)
    //             // makeMinOrderValueToTwoDecimal(minimumOrderValue)
    //           );
    //         });
    //       }
    //     }
    //   }
    // }
  }, [checkMinimum]);

  const outletShiftDetail = useSelector(
    (state) => state.outletShift.outletShiftDtl.data
  );
  const geographyDetail = useSelector(
    (state) => state.geographyMST.geography.data
  );

  // {letsRound(
  //   (subTotal ?? 0) + getTotalOfSurcharges(subTotal, surcharges)
  // )}

  useEffect(() => {
    setCartTrackError(null);
    if (!outletShiftDetail) {
      dispatch(outletShiftActions.getOutletShiftDTL());
    }
    // if (!geographyDetail) {
    //   dispatch(geographyMSTActions.getAll());
    // }
    if (surcharges.length === 0) {
      dispatch(cartActions.loadSurcharges());
    }
    // dispatch(cartActions.syncCartTracking(CART_TRACKING_PAGES["check-out"]));
  }, []);

  useEffect(() => {
    if (!loadingGetMinimumOrder) {
      if (subTotal >= minOrderValue || orderTypeForMinOrder.data == "OT01") {
        setCartTrackError(null);
      } else {
        setCartTrackError(
          <>
            <span>
              You'll need to add more to your cart. Minimum Delivery is{" "}
            </span>
            <span style={{ fontWeight: "bold" }}>${minOrderValue}</span>{" "}
          </>
        );
      }
    }
  }, [subTotal, minOrderValue, loadingGetMinimumOrder, orderTypeForMinOrder]);

  const swapOfferIteam = (e) => {
    if (e.recipeObj?.Category?.name) {
      if (window.location.href.includes("/menu/")) {
        activeSwap(e.itemGroupCode, e.recipeObj?.Category?.name);
      } else {
        history.push(`${ROUTES.menu._path}/${e.itemGroupCode}/default`);
        activeSwap(e.itemGroupCode, e.recipeObj?.Category?.name);
      }
    } else {
      history.push(`${ROUTES.menu._path}/${e.itemGroupCode}/default`);
      activeSwap(e.itemGroupCode, null);
    }
    dispatch(
      cartActions.setOfferAction({ add: false, remove: false, swap: true })
    );
    dispatch(cartSlice.actions.setCombSeq(e.combSeq));
    dispatch(cartSlice.actions.setSizeId(e?.recipeObj?.Size?.sortOrder));
    dispatch(cartActions.swapOfferItem(e));

    dispatch(cartActions.setShowPopUpCart(false));
    dispatch(
      cartActions.setOfferAction({ add: false, remove: false, swap: false })
    );
    sessionStorage.setItem("swap", JSON.stringify(true));
  };

  const setAddOfferItem = (e) => {
    dispatch(
      cartActions.setOfferAction({ add: true, remove: false, swap: false })
    );
    dispatch(cartActions.addOfferItem(e));
    if (e.category?.recipeObj?.Category?.name) {
      activeSwap(e.itemGroupCode, e.category?.recipeObj?.Category?.name);
      history.push(`${ROUTES.menu._path}/${e.itemGroupCode}/default`);
    } else {
      history.push(`${ROUTES.menu._path}/${e.itemGroupCode}/default`);
    }
    dispatch(cartActions.setShowPopUpCart(false));
    dispatch(
      cartActions.setOfferAction({ add: false, remove: false, swap: false })
    );
  };
  const removeOfferItem = (index, id) => {
    dispatch(
      cartActions.setOfferAction({ add: false, remove: true, swap: false })
    );
    dispatch(cartActions.deleteOfferItem(index, id));
    dispatch(
      cartActions.setOfferAction({ add: false, remove: false, swap: false })
    );
  };
  const calcToppingMaxQty = (recipeObj, topping) => {
    const maxToppings = recipeObj.toppingsInfo.Toppings.maximumQuantity;
    const { totalQtyOfToppings } = calcToppings(recipeObj.Toppings);
    return maxToppings - totalQtyOfToppings === 0
      ? topping.itemQuantity
      : topping.maximumQuantity;
  };
  useEffect(() => {
    if (!Array.isArray(items)) return;
    let sum = 0;
    items.forEach((each) => {
      sum += each.qty;
    });
    return setCartCount(sum);
  });
  const debouncedQtyInc = useDebounce((id, nextValue) => {
    incQty(id, nextValue);
  }, 1000);
  const incQty = (id, nextVal) => {
    dispatch(cartActions.setQty(id, nextVal));
  };
  const incHnhToppingQty = (cartItemId, toppingId, val, side) => {
    dispatch(cartActions.setQtyOfTopping(cartItemId, toppingId, val, side));
  };
  const debouncedHnhToppingQtyInc = useDebounce(
    (cartItemId, toppingId, val, side) =>
      incHnhToppingQty(cartItemId, toppingId, val, side),
    1000
  );
  const incOfferToppingQty = (cartItemId, toppingId, val, index) => {
    dispatch(
      cartActions.setQtyOfOfferTopping(cartItemId, toppingId, val, index)
    );
  };
  const debouncedOfferToppingQtyInc = useDebounce(
    (cartItemId, toppingId, val, index) =>
      incOfferToppingQty(cartItemId, toppingId, val, index),
    10
  );
  useEffect(() => {
    if (isMobileDevice()) {
      let data = JSON.parse(sessionStorage.getItem("swap"));
      if (offerLocalId && data === true) {
        if (document.scrollIntoView) {
          document.getElementById(offerLocalId).scrollIntoView({
            smooth: "easeInOutQuart",
          });
          sessionStorage.setItem("swap", JSON.stringify(false));
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if(itemsData?.length > 2){
  //   let lastElement = itemsData[itemsData?.length - 1];
  //   if(lastElement){
  //     setTimeout(() => {
  //       document.getElementById("scrollBottom").scrollIntoView();
  //       }, 500);
  //   }}
  // }, [itemsData])

  useEffect(() => {
    if (itemsData) {
      setTimeout(() => {
        document.getElementById("scrollBottom")?.scrollIntoView(false);
      }, 100);
    }
  }, [cartCount]);

  useEffect(() => {
    let data = { ...items };
    let sortData = Object.values(data)?.sort(
      (a, b) => parseFloat(a.localId) - parseFloat(b.localId)
    );
    setItemsdata(sortData);
  }, [items]);

  return (
    <div
      className="cart"
      style={{
        maxHeight: window.innerHeight - 20 + "px",
        ...(showPopUpCart ? { paddingTop: 0, margin: "5px" } : {}),
      }}
    >
      <div className="cart-header">
        {!!totalItems && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                voucher.appliedOn !== 0 &&
                voucher.appliedVoucher.couponCode === voucherCode
              ) {
                dispatch(cartActions.deleteVoucherCode());
              } else {
                dispatch(cartActions.applyVoucherCode(voucherCode));
              }
            }}
          >
            {!voucher.loading && voucher.error && (
              <small className="text-warning">{voucher.error}</small>
            )}
            <label className="apply-coupon-label">
              <input
                style={{ fontSize: "revert", width: '100%', height: '35px' }}
                required
                placeholder="Enter Voucher Code"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
              />
              <button disabled={voucher.loading}>
                {voucher.loading
                  ? "Applying..."
                  : voucher.appliedOn !== 0 &&
                    voucher.appliedVoucher.couponCode === voucherCode
                  ? "Remove"
                  : "Apply"}
              </button>
            </label>
          </form>
        )}
        Your Cart has: {cartCount} Items
      </div>
      <div className="cart-body">
        {/* {loading && <small style={{ borderRadius: '5px' }} className="text-dark bg-light border mt-1 rounded sticky-top">Loading...</small>} */}
        {!loading && error && (
          <small className="text-light bg-danger mt-1 rounded">
            Error: {error}
          </small>
        )}
        {totalItems === 0 ? (
          <div className="empty-cart">
            <img src={cartImg} alt="Cart Is Empty" />
            <div className="text-main">YOUR CART IS EMPTY</div>
            <div className="text-sub">
              Please add some items from the menu or offers
            </div>
          </div>
        ) : (
          <>
            <div className="cart-item-list">
              {itemsData?.map((cartItem) => {

                return cartItem.isHnh ? (
                  <>
                    <div
                      title={
                        activeSubpage === "half-and-half" &&
                        cartItem.localId === localIdForHnhEdit
                          ? // &&
                            // cartItem.localId === hnhLocalId
                            "This item is in editing mode you can't edit here"
                          : undefined
                      }
                      className={`cart-item ${
                        activeSubpage === "half-and-half" &&
                        cartItem.localId === localIdForHnhEdit
                          ? // &&
                            // cartItem.localId === hnhLocalId
                            "cart-item-disabled hnh-cart-item"
                          : "hnh-cart-item"
                      }`}
                      key={cartItem.localId}
                      id={cartItem.localId + "test"}
                    >
                      <div className="cart-item-header">
                        <div className="header-text">
                          <div className="text1">{`${cartItem.size} ${cartItem.base}`}</div>
                          {/* <div className="text2">{`${cartItem.itemObj.shortName}`}</div> */}
                        </div>
                      </div>
                      <div className="qty-price">
                        <div className="qty-counter">
                          {activeSubpage === "payment" ? (
                            <PaymentIncDec
                              value={cartItem.qty}
                              minusIcon={
                                cartItem.qty === 1 ? (
                                  <i className="fas fa-trash-alt"></i>
                                ) : undefined
                              }
                            />
                          ) : (
                            <IncDecCounter
                              value={cartItem.qty}
                              onChange={(val, isDec) => {
                                val > 0
                                  ? dispatch(
                                      cartActions.setQty(cartItem.localId, val)
                                    )
                                  : dispatch(
                                      cartActions.deleteItem(cartItem.localId)
                                    );
                              }}
                              minusIcon={
                                cartItem.qty === 1 ? (
                                  <i className="fas fa-trash-alt"></i>
                                ) : undefined
                              }
                            />
                          )}
                        </div>
                        <div className="price-text">
                          $
                          {(
                            letsFloor(
                              cartItem.left.price +
                                cartItem.right.price +
                                cartItem.left.selectedBasePrice +
                                cartItem.right.selectedBasePrice
                            ) * cartItem.qty
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className="side">
                        <div className="cart-item-header">
                          <ImageWithAltImage src={cartItem.left.image} />
                          <div className="header-text">
                            <div className="text2">{`Left: ${cartItem.left.itemObj.name}`}</div>
                          </div>
                        </div>
                        <div className="toppings">
                          <ToppingsCollapse
                            title={
                              <span className="collapse-title">
                                <span>
                                  Toppings ({cartItem.left.totalToppings})
                                </span>
                                <span>
                                  ${cartItem.left.toppingsPrice * cartItem.qty}
                                </span>
                              </span>
                            }
                          >
                            {cartItem?.left?.recipeObj?.Toppings?.filter(
                              (x) => x.itemQuantity
                            )?.map((topping) => {
                              return (
                                <div className="topping-cart-item">
                                  <div className="topping-name">
                                    {topping.name}
                                  </div>
                                  <div className="topping-qty-counter">
                                    {activeSubpage === "payment" ? (
                                      <PaymentIncDec
                                        value={topping.itemQuantity}
                                        minusIcon={
                                          topping.itemQuantity === 1 ? (
                                            <i className="fas fa-trash-alt"></i>
                                          ) : undefined
                                        }
                                      />
                                    ) : (
                                      <IncDecCounter
                                        value={topping.itemQuantity}
                                        min={topping.minimumQuantity}
                                        max={calcToppingMaxQty(
                                          cartItem.left.recipeObj,
                                          topping
                                        )}
                                        onChange={(val, isDec) => {
                                          val > 0
                                            ? dispatch(
                                                cartActions.setQtyOfTopping(
                                                  cartItem.localId,
                                                  topping.id,
                                                  val,
                                                  "left"
                                                )
                                              )
                                            : dispatch(
                                                cartActions.deleteTopping(
                                                  cartItem.localId,
                                                  topping.id,
                                                  "left"
                                                )
                                              );

                                          // if (val > 0) {
                                          //   if (isDec) {
                                          //     topping = {
                                          //       ...topping,
                                          //       itemQuantity:
                                          //         topping.itemQuantity - 1,
                                          //     };
                                          //   } else {
                                          //     topping = {
                                          //       ...topping,
                                          //       itemQuantity:
                                          //         topping.itemQuantity + 1,
                                          //     };
                                          //   }
                                          //   debouncedHnhToppingQtyInc(
                                          //     cartItem.localId,
                                          //     topping.id,
                                          //     topping.itemQuantity,
                                          //     "left"
                                          //   );
                                          // } else {
                                          //   dispatch(
                                          //     cartActions.deleteTopping(
                                          //       cartItem.localId,
                                          //       topping.id,
                                          //       "left"
                                          //     )
                                          //   );
                                          // }
                                        }}
                                        minusIcon={
                                          topping.itemQuantity === 1 ? (
                                            <i className="fas fa-trash-alt"></i>
                                          ) : undefined
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </ToppingsCollapse>
                        </div>
                      </div>
                      <div className="side">
                        <div className="cart-item-header">
                          <ImageWithAltImage src={cartItem.right.image} />
                          <div className="header-text">
                            <div className="text2">{`Right: ${cartItem.right.itemObj.name}`}</div>
                          </div>
                        </div>

                        <div className="toppings">
                          <ToppingsCollapse
                            title={
                              <span className="collapse-title">
                                <span>
                                  Toppings ({cartItem.right.totalToppings})
                                </span>
                                <span>
                                  $
                                  {cartItem.right.toppingsPrice *
                                    cartItem.qty ?? 0}
                                </span>
                              </span>
                            }
                          >
                            {cartItem?.right?.recipeObj?.Toppings?.filter(
                              (x) => x.itemQuantity
                            )?.map((topping) => {
                              return (
                                <div className="topping-cart-item">
                                  <div className="topping-name">
                                    {topping.name}
                                  </div>
                                  <div className="topping-qty-counter">
                                    {activeSubpage === "payment" ? (
                                      <PaymentIncDec
                                        value={topping.itemQuantity}
                                        minusIcon={
                                          topping.itemQuantity === 1 ? (
                                            <i className="fas fa-trash-alt"></i>
                                          ) : undefined
                                        }
                                      />
                                    ) : (
                                      <IncDecCounter
                                        value={topping.itemQuantity}
                                        min={topping.minimumQuantity}
                                        max={calcToppingMaxQty(
                                          cartItem.right.recipeObj,
                                          topping
                                        )}
                                        onChange={(val, isDec) => {
                                          val > 0
                                            ? dispatch(
                                                cartActions.setQtyOfTopping(
                                                  cartItem.localId,
                                                  topping.id,
                                                  val,
                                                  "right"
                                                )
                                              )
                                            : dispatch(
                                                cartActions.deleteTopping(
                                                  cartItem.localId,
                                                  topping.id,
                                                  "right"
                                                )
                                              );
                                        }}
                                        minusIcon={
                                          topping.itemQuantity === 1 ? (
                                            <i className="fas fa-trash-alt"></i>
                                          ) : undefined
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </ToppingsCollapse>
                        </div>
                      </div>

                      {activeSubpage === "payment" ? (
                        <div className="delete-edit-btn">
                          <button className="edit-btn" disabled="true">
                            <i className="far fa-edit"></i>
                          </button>
                          <button className="delete-btn" disabled="true">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      ) : (
                        <div className="delete-edit-btn">
                          <button
                            className="edit-btn"
                            onClick={() => {
                              dispatch(
                                hnhSlice.actions.setLocalId(cartItem.localId)
                              );
                              onEditBtnClick(
                                cartItem,
                                cartItem.isHnh ? "hnh" : "customize"
                              );
                            }}
                          >
                            <i className="far fa-edit"></i>
                          </button>
                          <button
                            className="delete-btn"
                            onClick={() =>
                              dispatch(cartActions.deleteItem(cartItem.localId))
                            }
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      )}

                      <div>
                        {cartItem.hnhSurchargeAmt ? (
                          <div className="d-flex">
                            <div className="">
                              <div
                                style={{ width: "130px", textAlign: "left" }}
                                className="text1"
                              >
                                H n H Surcharge:
                              </div>
                            </div>
                            <div
                              style={{ width: "50%" }}
                              className="price-text  text-right"
                            >
                              ${cartItem.hnhSurchargeAmt * cartItem.qty}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : // -----------------------------------offer-------------------------
                cartItem.offeritem ? (
                  <div className="offersCart">
                    <>
                      <div
                        className="offerCartitem"
                        key={cartItem.localId}
                        id={cartItem.localId}
                      >
                        <div className="cart-item-header ">
                          <ImageWithAltImage src={cartItem.image} />
                          <div className="w-100">
                            <div className="d-flex">
                              <div className="header-text">
                                <div className="text2">{`${cartItem.name}`}</div>
                              </div>
                              <div className="price-text font-weight-bold">
                                ${(cartItem.price * cartItem.qty).toFixed(2)}
                              </div>
                            </div>
                            <div>
                              <div
                                className="qty-price"
                                style={{ margin: "0px 0px 0px 10px" }}
                              >
                                <div className="qty-counter">
                                  {activeSubpage === "payment" ? (
                                    <PaymentIncDec
                                      value={cartItem.qty}
                                      minusIcon={
                                        cartItem.qty === 1 ? (
                                          <i className="fas fa-trash-alt"></i>
                                        ) : undefined
                                      }
                                    />
                                  ) : (
                                    <IncDecCounter
                                      value={cartItem.qty}
                                      onChange={(val, isDec) => {
                                        val > 0
                                          ? dispatch(
                                              cartActions.setQty(
                                                cartItem.localId,
                                                val
                                              )
                                            )
                                          : dispatch(
                                              cartActions.deleteItem(
                                                cartItem.localId
                                              )
                                            );
                                        // if (val > 0) {
                                        //   if (isDec) {
                                        //     cartItem = {
                                        //       ...cartItem,
                                        //       qty: cartItem.qty - 1,
                                        //     };
                                        //   } else {
                                        //     cartItem = {
                                        //       ...cartItem,
                                        //       qty: cartItem.qty + 1,
                                        //     };
                                        //   }
                                        //   debouncedQtyInc(
                                        //     cartItem.localId,
                                        //     cartItem.qty
                                        //   );
                                        // } else {
                                        //   dispatch(
                                        //     cartActions.deleteItem(
                                        //       cartItem.localId
                                        //     )
                                        //   );
                                        // }
                                      }}
                                      minusIcon={
                                        cartItem.qty === 1 ? (
                                          <i className="fas fa-trash-alt"></i>
                                        ) : undefined
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />

                        {cartItem.offeritem
                          ? cartItem.offeritem.map((data, index) => {
                              return (
                                <>
                                  <div
                                    key={cartItem.localId}
                                    id={cartItem.localId}
                                  >
                                    <div className="cart-item-header">
                                      {/* <ImageWithAltImage src= {`${data?.image} ?"nun"` }/> */}
                                      <div style={{ width: "100%" }}>
                                        <div className="header-text toppings">
                                          {
                                            <div className="textOffer">
                                              {`${
                                                `${data.itemObj.qty} X ` +
                                                  // `${data.qty} X ` +
                                                  (data?.recipeObj?.Category
                                                    ?.name === "1.25L"
                                                    ? data?.recipeObj?.Category
                                                        ?.name
                                                    : "") +
                                                  " " +
                                                  data?.name ?? "nun"
                                              } `}
                                              {data?.recipeObj?.Category &&
                                              data?.additionalPrice > 0 ? (
                                                <div className="price-text text-right">
                                                  $
                                                  {(data?.additionalPrice).toFixed(
                                                    2
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                          }
                                          {(data.recipeObj?.Size ||
                                            data.recipeObj?.Base) && (
                                            <div className="text1 d-flex justify-content-between">
                                              <span>
                                                {`${
                                                  data?.recipeObj?.Size?.name ??
                                                  ""
                                                } ${data?.baseName ?? ""}`}
                                              </span>
                                              {data?.selectedBasePrice > 0 ? (
                                                <span>
                                                  ${data.selectedBasePrice}
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                          <div className="qty-price mt-0 pl-1">
                                            <div className="qty-counter">
                                              {data?.action ? (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    paddingTop: "3px",
                                                  }}
                                                >
                                                  {/* {data?.action ? (
                                                    activeSubpage ===
                                                    "payment" ? (
                                                      <button
                                                        className="removeBtn"
                                                        disabled="true"
                                                        type="button"
                                                        style={{
                                                          backgroundColor:
                                                            "#444",
                                                        }}
                                                      >
                                                        Remove
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="removeBtn"
                                                        onClick={() => {
                                                          dispatch(
                                                            cartSlice.actions.setCombSeq(
                                                              cartItem.combinationSequence
                                                            )
                                                          );
                                                          dispatch(
                                                            cartActions.deleteOfferItem(
                                                              data.index,
                                                              cartItem.id,
                                                              data,
                                                              cartItem.combinationSequence
                                                            )
                                                          );
                                                        }}
                                                        type="button"
                                                      >
                                                        Remove
                                                      </button>
                                                    )
                                                  ) : null} */}
                                                  {data?.action ? (
                                                    activeSubpage ===
                                                    "payment" ? (
                                                      <button
                                                        className={
                                                          swap &&
                                                          data.index ===
                                                            numItem &&
                                                          data.combSeq ===
                                                            combSeqForSwap
                                                            ? "swapBtnActive"
                                                            : "swapBtn"
                                                        }
                                                        type="button"
                                                        id={
                                                          "button-" + data.index
                                                        }
                                                        disabled="true"
                                                        style={{
                                                          backgroundColor:
                                                            "#edcc5f",
                                                        }}
                                                      >
                                                        Swap
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className={
                                                          swap &&
                                                          data.index ===
                                                            numItem &&
                                                          data.combSeq ===
                                                            combSeqForSwap
                                                            ? "swapBtnActive"
                                                            : "swapBtn"
                                                        }
                                                        onClick={() => {
                                                          swapOfferIteam({
                                                            ...data,
                                                            offerLocalId:
                                                              cartItem.localId,
                                                            combSeq:
                                                              data.combSeq,
                                                          });
                                                          setNumItem(
                                                            data.index
                                                          );
                                                        }}
                                                        type="button"
                                                        // class="swapBtn"
                                                        id={
                                                          "button-" + data.index
                                                        }
                                                      >
                                                        Swap
                                                      </button>
                                                    )
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>

                                          {/* <div className="price-text">
                                            $
                                            {letsFloor(
                                              letsFloor(data.additionalPrice) +
                                                (data.selectedBasePrice ?? 0) + 
                                                (data.selectedSaucePrice ?? 0)
                                            )} */}
                                          {/* {(
                                        letsFloor(
                                          cartItem.price +
                                            cartItem.selectedBasePrice -
                                            (savedAmount &&
                                            voucher?.appliedOn ===
                                              cartItem.localId
                                              ? savedAmount
                                              : 0)
                                        ) * cartItem.qty
                                      ).toFixed(2)} */}
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </div>

                                    {data?.recipeObj?.Toppings ? (
                                      <div className="toppings">
                                        <ToppingsCollapse
                                          title={
                                            <span className="collapse-title">
                                              <span>
                                                Toppings (
                                                {
                                                  data?.recipeObj?.Toppings.filter(
                                                    (el) => el.itemQuantity
                                                  ).length
                                                }
                                                )
                                                <div>
                                                  {data?.recipeObj?.topping}
                                                </div>
                                              </span>
                                              <span>
                                                $
                                                {(
                                                  (data.toppingsPrice ?? 0) *
                                                  cartItem.qty
                                                ).toFixed(2)}
                                              </span>
                                            </span>
                                          }
                                        >
                                          {data?.recipeObj?.Toppings.filter(
                                            (x) => x.itemQuantity
                                          ).map((topping) => {
                                            return (
                                              <div className="topping-cart-item">
                                                <div className="topping-name">
                                                  {topping.name}
                                                </div>
                                                <div className="topping-qty-counter">
                                                  {activeSubpage ===
                                                  "payment" ? (
                                                    <PaymentIncDec
                                                      minusIcon={
                                                        topping.itemQuantity ===
                                                        1 ? (
                                                          <i className="fas fa-trash-alt"></i>
                                                        ) : undefined
                                                      }
                                                    />
                                                  ) : (
                                                    <IncDecCounter
                                                      value={
                                                        topping.itemQuantity
                                                      }
                                                      min={
                                                        topping.minimumQuantity
                                                      }
                                                      max={calcToppingMaxQty(
                                                        data?.recipeObj,
                                                        topping
                                                      )}
                                                      onChange={(
                                                        val,
                                                        isDec
                                                      ) => {
                                                        val > 0
                                                          ? dispatch(
                                                              cartActions.setQtyOfOfferTopping(
                                                                cartItem.localId,
                                                                topping.id,
                                                                val,
                                                                data.index
                                                              )
                                                            )
                                                          : dispatch(
                                                              cartActions.deleteTopping(
                                                                cartItem.localId,
                                                                topping.id,
                                                                "",
                                                                data.index
                                                              )
                                                            );

                                                        // if (val > 0) {
                                                        //   if (isDec) {
                                                        //     topping = {
                                                        //       ...topping,
                                                        //       itemQuantity:
                                                        //         topping.itemQuantity -
                                                        //         1,
                                                        //     };
                                                        //   } else {
                                                        //     topping = {
                                                        //       ...topping,
                                                        //       itemQuantity:
                                                        //         topping.itemQuantity +
                                                        //         1,
                                                        //     };
                                                        //   }
                                                        //   debouncedOfferToppingQtyInc(
                                                        //     cartItem.localId,
                                                        //     topping.id,
                                                        //     topping.itemQuantity,
                                                        //     data.index
                                                        //   );
                                                        // } else {
                                                        //   dispatch(
                                                        //     cartActions.deleteTopping(
                                                        //       cartItem.localId,
                                                        //       topping.id,
                                                        //       data.index
                                                        //     )
                                                        //   );
                                                        // }
                                                      }}
                                                      minusIcon={
                                                        topping.itemQuantity ===
                                                        1 ? (
                                                          <i className="fas fa-trash-alt"></i>
                                                        ) : undefined
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </ToppingsCollapse>
                                      </div>
                                    ) : null}
                                    {activeSubpage === "payment" ? (
                                      <div className="delete-edit-btn">
                                        {data?.recipeObj?.Toppings &&
                                        data.itemGroupCode === "G1" ? (
                                          <button
                                            className="edit-btn"
                                            disabled="true"
                                          >
                                            <i className="far fa-edit"></i>
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="delete-edit-btn">
                                        {data?.recipeObj?.Toppings &&
                                        data.itemGroupCode === "G1" ? (
                                          <button
                                            className="edit-btn"
                                            onClick={() => {
                                              dispatch(
                                                cartSlice.actions.setCombSeq(
                                                  data.combSeq
                                                )
                                              );

                                              onEditBtnClick(data, "customize");
                                            }}
                                          >
                                            <i className="far fa-edit"></i>
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}
                                    <hr
                                      style={{
                                        margin: "10px 0px",
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            })
                          : null}
                        {cartItem.removedItemGroup
                          ? cartItem.removedItemGroup.map((data, index) => {
                              return (
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(
                                      cartSlice.actions.setCombSeq(
                                        cartItem.combinationSequence
                                      )
                                    );
                                    setAddOfferItem({
                                      combSeq: cartItem.combinationSequence,
                                      index: data.index,
                                      comboRefId: cartItem.id,
                                      itemGroupCode: data.itemGroupCode,
                                      offerLocalId: cartItem.localId,
                                      category: data.data,
                                    });
                                  }}
                                  className="btn  btn-sm  mt-2"
                                  style={{
                                    width: "107%",
                                    marginLeft: "-7px",
                                    borderRadius: "0px",
                                    backgroundColor: "#fcaf17",
                                  }}
                                  id={"button-" + data.groupName}
                                >
                                  ADD {data.groupName}({data.numberOfitems})
                                </button>
                              );
                            })
                          : null}
                      </div>
                    </>
                  </div>
                ) : (
                  // --------------------------item-----------
                  <>
                    <div
                      title={
                        activeSubpage === "customize"
                          ? cartItem.localId === customizeLocalId
                            ? "This item is in editing mode you can't edit here "
                            : undefined
                          : activeSubpage === "build-your-own-pizza"
                          ? cartItem.localId === builtLocalId
                            ? "This item is in editing mode you can't edit here "
                            : undefined
                          : ""
                      }
                      // className={`cart-item ${(activeSubpage === "customize") ? (cartItem.builtPizza) ? (cartItem.localId === builtLocalId ? "cart-item-disabled" : "") : (cartItem.localId === customizeLocalId ? "cart-item-disabled" : "") : ""
                      //   }`}

                      className={`cart-item ${
                        activeSubpage === "customize"
                          ? cartItem.localId === customizeLocalId
                            ? "cart-item-disabled"
                            : ""
                          : activeSubpage === "build-your-own-pizza"
                          ? cartItem.localId === builtLocalId
                            ? "cart-item-disabled"
                            : ""
                          : ""
                      }`}
                      // className={`cart-item ${(activeSubpage === "customize") ? (cartItem.localId === customizeLocalId) ? "cart-item-disabled" : "" : activeSubpage === "build-your-own-pizza" ? "cart-item-disabled" : ""}`}
                      key={cartItem.localId}
                      id={cartItem.localId}
                    >
                      <div className="cart-item-header">
                        <ImageWithAltImage src={cartItem.image} />
                        <div className="header-text">
                          <div className="text2">{`${cartItem.name}`}</div>
                          {/* {(cartItem.recipeObj?.Size ||
                            cartItem.recipeObj?.Base) && (
                            <div className="text1">
                              {`${cartItem?.recipeObj?.Size?.name ?? ""} ${
                                cartItem?.baseName ?? ""
                              }`}{" "}
                            </div>
                          )} */}
                          {/* <div className="text2">{`${cartItem.name}`}</div> */}
                          <div
                            className="text1"
                            style={{ color: "#999", fontSize: "10pt" }}
                          >
                            {`${cartItem?.recipeObj?.Size?.name ?? ""} ${
                              cartItem?.baseName ?? ""
                            }`}
                          </div>

                          {cartItem.sauce && (
                            <div style={{ color: "#999", fontSize: "10pt" }}>
                              {cartItem.recipeObj.Sauce.filter(
                                (x) => x.active
                              ).map((sauce) => {
                                return <span>{sauce.name}</span>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="qty-price">
                        <div className="qty-counter">
                          {activeSubpage === "payment" ? (
                            <PaymentIncDec value={cartItem.qty} />
                          ) : (
                            <IncDecCounter
                              value={cartItem.qty}
                              onChange={(val, isDec) => {
                                val > 0
                                  ? dispatch(
                                      cartActions.setQty(cartItem.localId, val)
                                    )
                                  : dispatch(
                                      cartActions.deleteItem(cartItem.localId)
                                    );

                                // if (val > 0) {
                                // if (isDec) {
                                //   cartItem = {
                                //     ...cartItem,
                                //     qty: cartItem.qty - 1,
                                //   };
                                // } else {
                                //   cartItem = {
                                //     ...cartItem,
                                //     qty: cartItem.qty + 1,
                                //   };
                                // }
                                //   debouncedQtyInc(
                                //     cartItem.localId,
                                //     cartItem.qty
                                //   );
                                // }
                                //
                                //  else {
                                //   dispatch(
                                //     cartActions.deleteItem(cartItem.localId)
                                //   );
                                // }
                              }}
                              minusIcon={
                                cartItem.qty === 1 ? (
                                  <i className="fas fa-trash-alt"></i>
                                ) : undefined
                              }
                            />
                          )}
                        </div>
                        <div className="price-text">
                          $
                          {(
                            (letsRound(
                              parseFloat(cartItem.price) +
                                cartItem.selectedBasePrice +
                                (cartItem.selectedSaucePrice ?? 0)
                            ) -
                              (savedAmount &&
                              voucher?.appliedOn === cartItem.localId
                                ? savedAmount
                                : 0)) *
                            cartItem.qty
                          ).toFixed(2)}
                          {/* {console.log('price cart', cartItem)} */}
                          {/* $
                          {(
                            letsFloor(
                              letsRound(
                                parseFloat(cartItem.price) +
                                  cartItem.selectedBasePrice +
                                  (cartItem.selectedSaucePrice ?? 0)
                              ) -
                                (savedAmount &&
                                voucher?.appliedOn === cartItem.localId
                                  ? savedAmount
                                  : 0)
                            ) * cartItem.qty
                          ).toFixed(2)}  */}
                        </div>
                      </div>

                      {/* {cartItem.sauce.filter((x) => x.maximumQuantity)
                           .map((sauce) => {
                            return(
                              <div className="topping-cart-item">
                                 <div className="topping-name">
                                    {sauce.name}
                                  </div>
                              </div>
                            )
                           })

                           } */}

                      {/* {cartItem.sauce && (
                        <div className="toppings">
                          {cartItem.recipeObj.Sauce.filter(
                            (x) => x.itemQuantity
                          ).map((sauce) => {
                            return (
                              <SauceShow
                                title={
                                  <span className="collapse-title">
                                    <span>
                                      Sauce
                                      <span
                                        style={{
                                          color: "#999",
                                          fontSize: "10pt",
                                        }}
                                      >
                                        {" "}
                                        {`(${sauce.name})`}
                                      </span>
                                    </span>
                                    <span>
                                      $
                                      {(
                                        (cartItem.toppingsPrice ?? 0) *
                                        cartItem.qty
                                      ).toFixed(2)}
                                    </span>
                                  </span>
                                }
                              ></SauceShow>
                            );
                          })}
                        </div>
                      )} */}
                      {/* {cartItem.recipeObj.Sauce.filter(
                                 (x) => x.itemQuantity
                               ).map((sauce) => {
                                 console.log(cartItem)
                                 return (
                                   <div className="topping-cart-item">
                                     <div className="topping-name">
                                       {sauce.name}
                                     </div>
                                     <div className="topping-qty-counter">
                                       {activeSubpage === "payment" ? (
                                         <PaymentIncDec
                                           value={sauce.itemQuantity}
                                           minusIcon={
                                             sauce.itemQuantity === 1 ? (
                                               <i className="fas fa-trash-alt"></i>
                                             ) : undefined
                                           }
                                         />
                                       ) : (
                                         <IncDecCounter
                                           value={sauce.itemQuantity}
                                           min={sauce.minimumQuantity}
                                          //  max={calcToppingMaxQty(
                                          //    cartItem.recipeObj,
                                          //    sauce
                                          //  )}
                                           onChange={(val) => {
                                             val > 0
                                               ? dispatch(
                                                   cartActions.setQtyOfTopping(
                                                     cartItem.localId,
                                                     sauce.id,
                                                     val
                                                   )
                                                 )
                                               : dispatch(
                                                   cartActions.deleteTopping(
                                                     cartItem.localId,
                                                     sauce.id
                                                   )
                                                 );
                                           }}
                                           minusIcon={
                                            sauce.itemQuantity === 1 ? (
                                               <i className="fas fa-trash-alt"></i>
                                             ) : undefined
                                           }
                                         />
                                       )}
                                     </div>
                                   </div>
                                 );
                               })} */}

                      {console.log(cartItem)}

                      {cartItem.totalToppings > 0 && (
                        <div className="toppings">
                          <ToppingsCollapse
                            title={
                              <span className="collapse-title">
                                <span>Toppings ({cartItem.totalToppings})</span>
                                <span>
                                  $
                                  {(
                                    (cartItem.toppingsPrice ?? 0) * cartItem.qty
                                  ).toFixed(2)}
                                </span>
                              </span>
                            }
                          >
                            {cartItem.recipeObj.Toppings.filter(
                              (x) => x.itemQuantity
                            ).map((topping) => {
                              // console.log(cartItem);
                              return (
                                <div className="topping-cart-item">
                                  <div className="topping-name">
                                    {topping.name}
                                  </div>
                                  <div className="topping-qty-counter">
                                    {activeSubpage === "payment" ? (
                                      <PaymentIncDec
                                        value={topping.itemQuantity}
                                        minusIcon={
                                          topping.itemQuantity === 1 ? (
                                            <i className="fas fa-trash-alt"></i>
                                          ) : undefined
                                        }
                                      />
                                    ) : (
                                      <IncDecCounter
                                        value={topping.itemQuantity}
                                        min={topping.minimumQuantity}
                                        max={calcToppingMaxQty(
                                          cartItem.recipeObj,
                                          topping
                                        )}
                                        onChange={(val) => {
                                          val > 0
                                            ? dispatch(
                                                cartActions.setQtyOfTopping(
                                                  cartItem.localId,
                                                  topping.id,
                                                  val
                                                )
                                              )
                                            : dispatch(
                                                cartActions.deleteTopping(
                                                  cartItem.localId,
                                                  topping.id
                                                )
                                              );
                                        }}
                                        minusIcon={
                                          topping.itemQuantity === 1 ? (
                                            <i className="fas fa-trash-alt"></i>
                                          ) : undefined
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </ToppingsCollapse>
                        </div>
                      )}

                      <div className="delete-edit-btn">
                        {activeSubpage === "payment"
                          ? cartItem.itemObj.showCustimizeBtn && (
                              <button className="edit-btn" disabled="true">
                                <i className="far fa-edit"></i>
                              </button>
                            )
                          : cartItem.itemObj.showCustimizeBtn && (
                              <button
                                className="edit-btn"
                                onClick={() => {
                                  dispatch(
                                    cartSlice.actions.setLocalIdForBuildYourOwn(
                                      cartItem.localId
                                    )
                                  );

                                  onEditBtnClick(
                                    cartItem,
                                    cartItem.name.includes(
                                      "Build your own Pizza"
                                    )
                                      ? "builtPizza"
                                      : "customize"
                                    // cartItem.customize ? "customize" : null
                                  );
                                }}
                              >
                                <i className="far fa-edit"></i>
                                {/* CUST */}
                              </button>
                            )}
                        {activeSubpage === "payment" ? (
                          <button className="delete-btn" disabled="true">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        ) : (
                          <button
                            className="delete-btn"
                            onClick={() => {
                              dispatch(
                                cartActions.deleteItem(cartItem.localId)
                              );
                            }}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}

        <div className="subtotal" id="scrollBottom">
          {!!savedAmount && (
            <div className="subtotal-item" style={{ color: "green" }}>
              <span>Saved Amount</span>
              <span> - {letsRound(savedAmount ?? 0)}</span>
            </div>
          )}
          {deliveryCharge && deliveryCharge > 0 ? (
            <div className="subtotal-item">
              <span>Delivery Charge</span>
              <span>$ {letsRound(deliveryCharge)}</span>
            </div>
          ) : null}
          {surcharges
            ?.filter((x) => !x.halfNHalf)
            .map((sur, i) => (
              <div className="subtotal-item" key={i}>
                <span>{sur.surchargeName}</span>
                <span> $ {letsRound(calcSurchargeAmt(subTotal, sur))}</span>
              </div>
            ))}
          <div
            className="subtotal-item total"
            style={{ fontSize: "13pt", fontWeight: "600" }}
          >
            {/* in total it showing 16.89 but the accutal price is 16.90  */}
            <span>Total</span>
            <span>
              ${" "}
              {letsRound(
                (subTotal ?? 0) + getTotalOfSurcharges(subTotal, surcharges)
              )}
              {/* {parseInt(subTotal)} */}
            </span>
          </div>
        </div>
      </div>
      <div className="cart-footer">
        <div className="next-back-btns">
          <span>
            {!!backBtn && (
              <button onClick={backBtn.handler} className="back-btn">
                <i className="fas fa-caret-left"></i> {backBtn.text}
              </button>
            )}
          </span>

          <span>
            {activeSubpage !== "payment"
              ? !!nextBtn && (
                  <button onClick={nextBtn.handler} className="next-btn">
                    {nextBtn.text} <i className="fas fa-caret-right"></i>
                  </button>
                )
              : null}
          </span>
        </div>
      </div>

      {/* {
      loadingGetMinimumOrder ? 
        <Spinner />
       : <div style={{ color: "red" }}>{cartTrackError}</div>
    
      } */}

      {activeSubpage === "check-out" || activeSubpage === "payment" ? (
        orderType?.data ? (
          cartTrackError ? (
            <div className="minimum mt-2 ">
              <div>{cartTrackError}</div>
            </div>
          ) : null
        ) : null
      ) : null}
    </div>
  );
};

export default Cart;
