import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootreducer";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import dateValidationMiddleware from "./dateValidationMiddleware";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";


const persistConfig = {
    key: 'cart',
    storage: storage,
    whitelist: ['cart','orderMST'],
};

const pReducers = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: pReducers,
    middleware: [
        ...getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true
        }),
        dateValidationMiddleware , createStateSyncMiddleware() 
    ],
    devTools: process.env.NODE_ENV !== "production",
})

initMessageListener(store);

export const persistor = persistStore(store);